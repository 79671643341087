import {Component, HostListener, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions} from 'ngx-gallery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  public loadedImage = false;
  public loadedImage2 = false;
  public loadedContent = false;
  public loadedContent2 = false;
  public animation1 = false;
  public animation2 = false;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const clientHeight = document.documentElement.clientHeight;
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top1 = document.getElementById('animation1').getBoundingClientRect().top + scrollTop - clientTop;
    //const top2 = document.getElementById('animation2').getBoundingClientRect().top + scrollTop - clientTop;
    this.animation1 = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) + clientHeight > top1 ? true : false;
    //this.animation2 = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) + clientHeight > top2 ? true : false;
  }
  constructor() {}
  ngOnInit(): void {
    this.galleryOptions = [
      {
        imageAutoPlay: true,
        imageAutoPlayInterval: 5000,
        imageInfinityMove: true,
        //thumbnails: false,
        width: '100%',
        height: '700px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageSize: NgxGalleryImageSize.Cover,
        imageArrows: false,
        preview: false
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '10px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.galleryImages = [
      {
        small: 'assets/i/PLB_4917.jpg',
        medium: 'assets/i/PLB_4917.jpg'
      },
      {
        small: 'assets/i/PLB_4917.jpg',
        medium: 'assets/i/PLB_4918.jpg'
      },
      {
        small: 'assets/i/PLB_4917.jpg',
        medium: 'assets/i/PLB_4919.jpg'
      }
    ];
  }
  loadImage() {
    this.loadedImage = true;
    setTimeout( () => this.loadedContent = true , 500);
    setTimeout( () => this.loadedContent2 = true , 1000);
    setTimeout( () => this.loadedImage2 = true , 500);
  }
}
