import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';
import * as json from '../../assets/mock/translate.json';
@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  public translate = json.translate;
  public env = environment;
  constructor() {}
  transform(value): string {
    let result = '';
    if (value) {
      if (this.translate[value][this.env.lang]) {
        result = this.translate[value][this.env.lang];
      } else if (this.translate[value].en) {
        result = this.translate[value].en;
      }
    }
    return result;
  }
}
