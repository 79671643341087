import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-nsbox-list',
  templateUrl: './nsbox-list.component.html',
  styleUrls: ['./nsbox-list.component.less']
})
export class NsboxListComponent implements OnInit {
  @Input() imgGallery: any;
  public imgList = {};
  public products = [];
  public elements = [];
  public newColor = {};
  public lg;
  public queryParams;
  public env = environment;
  public host;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.host = this.env.getHost();
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    this.products = JSON.parse(JSON.stringify(json.products));
    this.elements = json.nsbox_table;
    for (const j of this.elements) {
      let colorVal = true;
      for (const key of j.ports) {
        this.imgList[j.id] = this.getImgList(key);
        for (const k of key.value) {
          const item = this.getProduct(k);
          if (item) {
            this.newColor[k + key.type] = colorVal;
            colorVal = !colorVal;
          }
        }
      }
    }
  }

  getProduct(name) {
    const result = this.products[0].catalog.find(item => (item.name === name && !(item.blocked && item.blocked.indexOf(this.host) > -1)));
    if (result && result.sets) {
      result.sets = result.sets.filter(item => !(item.blocked && item.blocked.indexOf(this.host) > -1));
    }
    return result;
  }
  getImgList(ports) {
    const imgArr = [];
    for (const j of ports.value) {
      const currentElement = this.getProduct(j);
      if (currentElement) {
        for (const k of currentElement.sets) {
          if (k.img) {
            imgArr.push(k);
          }
        }
      }
    }
    return imgArr;
  }
}
