import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductComponent} from './main-pages/product/product.component';
import {HomeComponent} from './main-pages/home/home.component';
import {NsbComponentView} from './product-details/nsb-view/nsbView.component';
import {ContactsComponent} from './main-pages/contacts/contacts.component';
import {NsboxComponent} from './product-details/nsbox/nsbox.component';
import {ExhibitionsComponent} from './main-pages/exhibitions/exhibitions.component';
import {ElectricalComponent} from './title-pages/electrical/electrical.component';
import {NsboxTitleComponent} from './title-pages/nsbox-title/nsbox-title.component';
import {SurveillanceComponent} from './product-details/surveillance/surveillance.component';
import {SurveillanceTitleComponent} from './title-pages/surveillance-title/surveillance-title.component';
import {NsbonComponent} from './title-pages/nsbon/nsbon.component';
import {NsbonViewComponent} from './product-details/nsbon-view/nsbon-view.component';
import {SwitchesComponent} from './title-pages/switches/switches.component';
import {SwitchViewComponent} from './product-details/switches-view/switch-view.component';
import {NewsComponent} from './main-pages/news/news.component';
import {CompanyComponent} from './main-pages/company/company.component';
import {SitemapComponent} from './main-pages/sitemap/sitemap.component';
import {ClientsComponent} from './main-pages/clients/clients.component';
import {Home2Component} from './main-pages/home2/home2.component';
import {WtbComponent} from './main-pages/wtb/wtb.component';
import {AwardsComponent} from './main-pages/awards/awards.component';
import {PartnersComponent} from './main-pages/partners/partners.component';
import {PoeInjectorComponent} from './title-pages/poe-injector/poe-injector.component';
import {PoeInjectorViewComponent} from './product-details/poe-injector-view/poeInjectorView.component';
import {SfpIndustrialTitleComponent} from './title-pages/sfp-industrial-title/sfp-industrial-title.component';
import {HomeMobileComponent} from './home-mobile/home-mobile.component';
import {Home3Component} from './main-pages/home3/home3.component';
import {BackupsolutionsComponent} from './product-details/backupsolutions/backupsolutions.component';
import {BackupsolutionsTitleComponent} from './title-pages/backupsolutions-title/backupsolutions-title.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'products', component: ProductComponent },
  { path: 'contacts', component: ContactsComponent},
  { path: 'enclosure', component: NsbComponentView },
  { path: 'nsbon-title', component: NsbonComponent },
  { path: 'switches-title', component: SwitchesComponent },
  { path: 'nsbox', component: NsboxComponent },
  { path: 'events', component: ExhibitionsComponent},
  { path: 'electrical', component: ElectricalComponent},
  { path: 'surveillance', component: SurveillanceComponent},
  { path: 'backupsolutions', component: BackupsolutionsComponent},
  { path: 'nsbox-title', component: NsboxTitleComponent},
  { path: 'nsbon', component: NsbonViewComponent},
  { path: 'about-us', component: CompanyComponent},
  { path: 'switch', component: SwitchViewComponent},
  { path: 'news', component: NewsComponent},
  { path: 'wtb', component: WtbComponent},
  { path: 'partners', component: PartnersComponent},
  { path: 'surveillance-title', component: SurveillanceTitleComponent},
  { path: 'backupsolutions-title', component: BackupsolutionsTitleComponent},
  { path: 'sitemap', component: SitemapComponent},
  { path: 'clients', component: ClientsComponent},
  { path: 'awards', component: AwardsComponent},
  { path: 'poe-injector-title', component: PoeInjectorComponent },
  { path: 'sfp-industrial-title', component: SfpIndustrialTitleComponent },
  { path: 'poe-injector', component: PoeInjectorViewComponent },
  { path: 'homeMobile', component: HomeMobileComponent },
  { path: 'home2', component: Home3Component },
  { path: '**', component: Home3Component },
  { path: 'home', component: Home3Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
