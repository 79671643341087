import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less']
})
export class ContactsComponent implements OnInit {
  public env = environment;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.env.lang = this.env.allLang.indexOf(queryParams.lg) > -1 ? queryParams.lg : 'en';
      this.env.queryParams = this.env.lang === 'en' ?  null : this.env.lang;
    });
  }
}
