import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDataComponent implements OnInit {
  @Input() specification: any;
  public lg;
  public queryParams;
  public env = environment;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
  }

}
