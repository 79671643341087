import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as json from '../../../assets/mock/wtb.json';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-wtb',
  templateUrl: './wtb.component.html',
  styleUrls: ['./wtb.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WtbComponent implements OnInit {
  public partners = [];
  public env = environment;
  public lg;
  public queryParams;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    const host = this.env.getHost();
    if (host === 'ae' || host === 'com') {
      this.partners = json.partners_ae;
    } else {
      this.partners = json.partners;
    }
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }

}
