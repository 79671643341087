import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyComponent implements OnInit {
  public loadedContent = false;
  public imagesList = [];
  public imagesList2 = [];
  public imagesList3 = [];
  public imagesList4 = [];
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const photos = ['5D4_0784.jpg', '5D4_0793.jpg', '5D4_0797.jpg', '5D4_0837.jpg', '5D4_0887.jpg', '5D4_0923.jpg', '5D4_0973.jpg', '5D4_1000.jpg', '5D4_1020.jpg'];
    const photos2 = ['stock_03.jpg', 'stock_04.jpg', 'stock_05.jpg', 'stock_06.jpg', 'stock_07.jpg', 'stock_08.jpg', 'stock_09.jpg', 'packing.jpg'];
    const photos3 = [
      'PLB_5140.jpg',
      'nsgate_03.jpg',
      'PLB_5169.jpg',
      '05388.jpg',
      'Dawn_2.jpg',
      'DSC06451.JPG',
      'DSC06930.JPG',
      'i18-33b.jpg',
      'PLB_4923.jpg',
      'PLB_4980.jpg',
      'PLB_5075.jpg',
      'PLB_5100.jpg',
      'PLB_5109.jpg',
      'PLB_5123.jpg',
      '5D4_4000.jpg',
      'PLB_5181.jpg',
      'd19-4420b.jpg',
      'PLB_5207.jpg',
      'PLB_5225.jpg',
      'PLB_5228.jpg',
    'PLB_5269.jpg',
    'PLB_5315.jpg',
    'PLB_5317.jpg',
      'DSC07075.JPG',
      'DSC07086.JPG',
      'DSC07220.JPG',
      'DSC07294.JPG',
      'DSC07383.JPG',
      'DSC07953.JPG',
    'PLB_5318.jpg',
    'PLB_5335.jpg',
    'PLB_5358.jpg',
    'PLB_5371.jpg',
    'PLB_5420.jpg',
    'PLB_5448.jpg',
    'PLB_5494.jpg',
    'PLB_5586.jpg',
    'sec-21_10.jpg', ];
    const photos4 = [
      'install_00.jpg',
      'install_01.jpg',
      'install_02.jpg',
      'install_03.jpg',
      'install_04.jpg',
      'install_05.jpg',
      'install_06.jpg',
      'install_07.jpg',
      'install_08.jpg',
      'install_09.jpg',
      'install_10.jpg',
      'install_11.jpg',
      'install_12.jpg',
      'install_13.jpg'
    ];
    photos.forEach(photo => {
      this.imagesList.push({
        image: '../../assets/i/exhibitions/company/' + photo,
        thumbImage: '../../assets/i/exhibitions/company/small/' + photo
      });
    });
    photos2.forEach(photo => {
      this.imagesList2.push({
        image: '../../assets/i/exhibitions/company/' + photo,
        thumbImage: '../../assets/i/exhibitions/company/small/' + photo
      });
    });
    photos3.forEach(photo => {
      this.imagesList3.push({
        image: '../../assets/i/exhibitions/company/faces/' + photo,
        thumbImage: '../../assets/i/exhibitions/company/small/faces/' + photo
      });
    });
    photos4.forEach(photo => {
      this.imagesList4.push({
        image: '../../assets/i/exhibitions/company/street/' + photo,
        thumbImage: '../../assets/i/exhibitions/company/small/street/' + photo
      });
    });
    setTimeout( () => {
      this.loadedContent = true;
      this.cdr.detectChanges();
    }, 500);
  }
}
