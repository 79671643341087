import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as json from '../../../assets/mock/news.json';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Home2Component implements OnInit {
  public loadedImage = false;
  public loadedImage2 = false;
  public loadedContent = false;
  public loadedContent2 = false;
  public news;
  public env = environment;
  public filteredNews = [];
  public lg;
  public queryParams;
  public products = [
    {
      title: {
        en: 'NSBox',
        ru: 'NSBox'
      },
      subtitle: {
        en: 'Outdoor access Nodes',
        ru: 'Уличные узлы доступа'
      },
      image: 'nsbox.jpg',
      link: 'nsbox-title'
    },
    {
      title: {
        en: 'NSBox Family',
        ru: 'Семейство NSBox'
      },
      subtitle: {
        en: 'NSBox access node Family',
        ru: 'Уличные Узлы Доступа'
      },
      image: 'family.jpg',
      link: 'surveillance-title'
    },
    {
      title: {
        en: 'NSB | NSP | NSC',
        ru: 'NSB | NSP | NSC'
      },
      subtitle: {
        en: 'Electrical enclosures',
        ru: 'Монтажные шкафы'
      },
      image: 'nsb.jpg',
      link: 'electrical'
    },
    {
      title: {
        en: 'NSBon',
        ru: 'NSBon'
      },
      subtitle: {
        en: 'Optional Accessories',
        ru: 'Дополнительная комплектация'
      },
      image: 'nsbon.jpg',
      link: 'nsbon-title'
    },
    {
      title: {
        en: 'Switches',
        ru: 'Коммутаторы'
      },
      subtitle: {
        en: 'Industrial Switches and Media Converters with PoE',
        ru: 'Промышленные коммутаторы и медиаконвертеры'
      },
      image: 'switch.jpg',
      link: 'switches-title'
    }
  ];
  public fonUrl;
  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    const host = this.env.getHost();
    this.news = json.news;
    this.route.queryParams.subscribe( () => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.filteredNews = this.news.filter(obj => (obj.lang.indexOf(this.lg) > -1 && !(obj.blocked && obj.blocked.indexOf(host) > -1)));
      //this.fonUrl = (host === 'ae') ? '../../assets/i/fon3.jpg' : '../../assets/i/PLB_5109.jpg';
      this.fonUrl = '../../assets/i/fon3.jpg';
      this.cdr.detectChanges();
    });
  }
  loadImage() {
    this.loadedImage = true;
    setTimeout( () => this.loadedContent = true , 500);
    setTimeout( () => this.loadedContent2 = true , 1000);
    setTimeout( () => this.loadedImage2 = true , 500);
  }
}

