import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import * as json from '../../../assets/mock/partners.json';

@Component({
  selector: 'app-wtb',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersComponent implements OnInit {
  public env = environment;
  public lg;
  public queryParams;
  public partners = [];
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
    this.partners = json.partners;
    this.cdr.detectChanges();
  }

}
