import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-backupsolutions-title',
  templateUrl: './backupsolutions-title.component.html',
  styleUrls: ['./backupsolutions-title.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BackupsolutionsTitleComponent implements OnInit {
  public products = [];
  public product: any = {};
  public loadedContent = true;
  public animation1 = false;
  public env = environment;
  public lg;
  public queryParams;
  public imgList = [
    {img: 'cloudy_1w.png'},
    {img: 'nobelic_4.png'},
    {img: 'nsbox-124sl-30_tb.png'},
    {img: 'nsbox-124s-90_tb.png'},
    {img: 'frontcPS.png'},
    {img: 'sidecPS.png'},
    {img: 'NSP-4060_f.png'},
    {img: 'NSBox-cPS.png'}
  ];
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products.filter(item => item.link === 'backupsolutions')[0];
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
}
