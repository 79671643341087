import {Component, OnInit, ElementRef, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';

@Component({
  selector: 'app-home-mobile',
  templateUrl: './home-mobile.component.html',
  styleUrls: ['./home-mobile.component.less',
    '../../../node_modules/keen-slider/keen-slider.min.css']
})
export class HomeMobileComponent  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderRef', {static: false}) sliderRef: ElementRef<HTMLElement>;

  public slider: KeenSliderInstance = null;
  public currentSlide = 0;
  public dotHelper: Array<number> = [];
  constructor(private cdRef: ChangeDetectorRef) {}
  ngOnInit() {}
  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: true,
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 4000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
    );
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ];
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }

}
