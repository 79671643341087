import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions} from 'ngx-gallery';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute, Router} from '@angular/router';
import {Nsb, Products} from '../../domain/main';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-surveillance-view',
  templateUrl: './surveillance.component.html',
  styleUrls: ['./surveillance.component.less']
})
export class SurveillanceComponent implements OnInit {
  selectedImage = 0;
  public name = '';
  public product = new Nsb();
  public imgList = [];
  products: Products[];
  public env = environment;
  public loadGallery = false;
  public lg;
  public queryParams;
  public productParent;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
    this.route.params.subscribe(params => {
      const id = params.id;
      this.products = JSON.parse(JSON.stringify(json.products));
      this.products.forEach(item => {
        const prod = item.catalog.find(el => el.id === id);
        if (prod) {
          this.productParent = item;
          this.product = prod;
          this.imgList = [];
          this.product.photo.forEach((photo, numb) => {
            this.imgList.push(
              {img: photo,
               width: this.product.photoWidth ? this.product.photoWidth[numb] : 'auto'
              });
          });
          this.loadGallery = false;
          setTimeout( () => {this.loadGallery = true; }, 1);
          this.cdr.detectChanges();
        }
      });
    });
  }
  public selectImage(i: number) {
    this.selectedImage = i;
  }
  public getBigImage() {
    return this.product.photo.length ? '../../assets/i/nsb/' + this.product.photo[this.selectedImage] : '';
  }
  public getSmallImage(i: number) {
    return this.product.photo.length ? '../../assets/i/nsb-mini/' + this.product.photo[i] : '';
  }
  public getData(specification) {
    if (specification['data'][this.env.lang]) {
      return specification['data'][this.env.lang];
    } else {
      return specification['data'];
    }
  }
}
