import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-nsbon-list',
  templateUrl: './nsbon-list.component.html',
  styleUrls: ['./nsbon-list.component.less']
})
export class NsbonListComponent implements OnInit {
  @Input() imgGallery: any;
  public imgList = {};
  public products = [];
  public elements = [];
  public newColor = {};
  public lg;
  public host;
  public queryParams;
  public env = environment;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.host = this.env.getHost();
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    this.products = JSON.parse(JSON.stringify(json.products));
    this.elements = json.nsbon_table;
    for (const j of this.elements) {
      let colorVal = true;
      for (const key of j.ports) {
        this.imgList[j.id] = this.getImgList(key);
        for (const k of key.value) {
          const item = this.getProduct(k);
          if (item) {
            this.newColor[k + key.type] = colorVal;
            colorVal = !colorVal;
          }
        }
      }
    }
  }

  getProduct(name) {
    const result = this.products[3].catalog.filter(item => (item.name === name && !(item.blocked && item.blocked.indexOf(this.host) > -1)));
    return result[0];
  }
  getImgList(ports) {
    const imgArr = [];
    for (const j of ports.value) {
      const currentElement = this.getProduct(j);
      if (currentElement) {
        for (const k of currentElement.sets) {
          if (k.img) {
            imgArr.push(k);
          }
        }
      }
    }
    return imgArr;
  }
}
