import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { MainNavComponent } from './main-pages/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatChipsModule, MatMenuModule} from '@angular/material';
import {APP_BASE_HREF, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ProductComponent} from './main-pages/product/product.component';
import { HomeComponent } from './main-pages/home/home.component';
import {NgxGalleryModule} from 'ngx-gallery';
import { LeftMenuComponent } from './service/left-menu/left-menu.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatTabsModule} from '@angular/material/tabs';
import {NsbComponentView} from './product-details/nsb-view/nsbView.component';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ParallaxDirective} from './service/directives/parallax.directive';
import { ContactsComponent } from './main-pages/contacts/contacts.component';
import { SnackComponent } from './service/snack/snack.component';
import {HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AngularYandexMapsModule, IConfig} from 'angular8-yandex-maps';
import { NsboxComponent } from './product-details/nsbox/nsbox.component';
import { ExhibitionsComponent } from './main-pages/exhibitions/exhibitions.component';
import { ElectricalComponent } from './title-pages/electrical/electrical.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { NsboxTitleComponent } from './title-pages/nsbox-title/nsbox-title.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ClickOutsideDirective} from './service/directives/click-outside.directive';
import {SurveillanceComponent} from './product-details/surveillance/surveillance.component';
import {SurveillanceTitleComponent} from './title-pages/surveillance-title/surveillance-title.component';
import {FeedbackComponent} from './main-pages/feedback/feedback.component';
import { NsbonComponent } from './title-pages/nsbon/nsbon.component';
import { NsbonViewComponent } from './product-details/nsbon-view/nsbon-view.component';
import {SwitchesComponent} from './title-pages/switches/switches.component';
import {SwitchViewComponent} from './product-details/switches-view/switch-view.component';
import { TableDataComponent } from './service/table-data/table-data.component';
import { NsboxListComponent } from './service/lists/nsbox-list/nsbox-list.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import { NsbListComponent } from './service/lists/nsb-list/nsb-list.component';
import { NsbonListComponent } from './service/lists/nsbon-list/nsbon-list.component';
import { SwitchesListComponent } from './service/lists/switches-list/switches-list.component';
import { FamilyListComponent } from './service/lists/family-list/family-list.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { NewsComponent } from './main-pages/news/news.component';
import { CompanyComponent } from './main-pages/company/company.component';
import { SitemapComponent } from './main-pages/sitemap/sitemap.component';
import { ClientsComponent } from './main-pages/clients/clients.component';
import { Home2Component } from './main-pages/home2/home2.component';
import { CapitalizeFirstPipe } from './pipes/capitalizefirst.pipe';
import { WtbComponent } from './main-pages/wtb/wtb.component';
import {AwardsComponent} from './main-pages/awards/awards.component';
import {PartnersComponent} from './main-pages/partners/partners.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { InsertHtmlComponent } from './service/insert-html/insert-html.component';
import {TranslatePipe} from './pipes/translate.pipe';
import {PoeInjectorComponent} from './title-pages/poe-injector/poe-injector.component';
import {PoeInjectorViewComponent} from './product-details/poe-injector-view/poeInjectorView.component';
import {PoeInjectorListComponent} from './service/lists/poe-injector-list/poe-injector-list.component';
import {SfpIndustrialTitleComponent} from './title-pages/sfp-industrial-title/sfp-industrial-title.component';
import {SfpIndustrialListComponent} from './service/lists/sfp-idustrial-title/sfp-industrial-list.component';
import { HomeMobileComponent } from './home-mobile/home-mobile.component';
import { SliderGalleryComponent } from './service/slider-gallery/slider-gallery.component';
import {BackupPowerSolutionsListComponent} from './service/lists/backup_power_solutions/backup_power_solutions-list.component';
import { Home3Component } from './main-pages/home3/home3.component';
import { BottomComponent } from './main-pages/bottom/bottom.component';
import {BackupsolutionsComponent} from './product-details/backupsolutions/backupsolutions.component';
import {BackupsolutionsTitleComponent} from './title-pages/backupsolutions-title/backupsolutions-title.component';
import {BackupListComponent} from './service/lists/backup-list/backup-list.component';
const mapConfig: IConfig = {
  apikey: 'API_KEY',
  lang: 'en_US',
};
@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    ProductComponent,
    HomeComponent,
    LeftMenuComponent,
    NsbComponentView,
    SurveillanceTitleComponent,
    SurveillanceComponent,
    BackupsolutionsComponent,
    BackupsolutionsTitleComponent,
    ParallaxDirective,
    ContactsComponent,
    SnackComponent,
    NsboxComponent,
    ExhibitionsComponent,
    ElectricalComponent,
    NsboxTitleComponent,
    ClickOutsideDirective,
    FeedbackComponent,
    NsbonComponent,
    SwitchesComponent,
    NsbonViewComponent,
    SwitchViewComponent,
    TableDataComponent,
    NsboxListComponent,
    BackupListComponent,
    NsbListComponent,
    NsbonListComponent,
    PoeInjectorListComponent,
    SfpIndustrialListComponent,
    SfpIndustrialTitleComponent,
    SwitchesListComponent,
    FamilyListComponent,
    BackupPowerSolutionsListComponent,
    NewsComponent,
    CompanyComponent,
    PoeInjectorComponent,
    PoeInjectorViewComponent,
    SitemapComponent,
    ClientsComponent,
    AwardsComponent,
    Home2Component,
    CapitalizeFirstPipe,
    WtbComponent,
    PartnersComponent,
    SafeHtmlPipe,
    TranslatePipe,
    CapitalizeFirstPipe,
    InsertHtmlComponent,
    HomeMobileComponent,
    SliderGalleryComponent,
    Home3Component,
    BottomComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatTreeModule,
    MatTabsModule,
    MatRippleModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    NgxGalleryModule,
    HttpClientModule,
    MatSnackBarModule,
    MatChipsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatRadioModule,
    AngularYandexMapsModule.forRoot(mapConfig)
  ],
  entryComponents: [
    SnackComponent
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
