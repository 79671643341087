import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-surveillance-title',
  templateUrl: './surveillance-title.component.html',
  styleUrls: ['./surveillance-title.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveillanceTitleComponent implements OnInit {
  public products = [];
  public product: any = {};
  public loadedContent = true;
  public animation1 = false;
  public env = environment;
  public lg;
  public queryParams;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const clientHeight = document.documentElement.clientHeight;
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top1 = document.getElementById('animation-series').getBoundingClientRect().top + scrollTop - clientTop;
    this.animation1 = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) + clientHeight > top1 ? true : false;
  }
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products.filter(item => item.link === 'surveillance')[0];
    //setTimeout( () => this.loadedContent = true , 500);
    this.route.queryParams.subscribe( () => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
}
