import { Component, OnInit } from '@angular/core';
import * as json from '../../../assets/mock/products.json';
import {ProductComponent} from '../product/product.component';
import {ContactsComponent} from '../contacts/contacts.component';
import {NsbComponentView} from '../../product-details/nsb-view/nsbView.component';
import {NsbonComponent} from '../../title-pages/nsbon/nsbon.component';
import {SwitchesComponent} from '../../title-pages/switches/switches.component';
import {NsboxComponent} from '../../product-details/nsbox/nsbox.component';
import {ExhibitionsComponent} from '../exhibitions/exhibitions.component';
import {ElectricalComponent} from '../../title-pages/electrical/electrical.component';
import {SurveillanceComponent} from '../../product-details/surveillance/surveillance.component';
import {NsboxTitleComponent} from '../../title-pages/nsbox-title/nsbox-title.component';
import {NsbonViewComponent} from '../../product-details/nsbon-view/nsbon-view.component';
import {CompanyComponent} from '../company/company.component';
import {SwitchViewComponent} from '../../product-details/switches-view/switch-view.component';
import {NewsComponent} from '../news/news.component';
import {SurveillanceTitleComponent} from '../../title-pages/surveillance-title/surveillance-title.component';
import {HomeComponent} from '../home/home.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.less']
})
export class SitemapComponent implements OnInit {
  public products = [];
  public env = environment;
  public siteLink = {
    ae: 'https://networklogic.ae/',
    eu: 'https://nsgate.eu/',
    com: 'https://nsgate.com/',
    localhost: 'https://nsgate.com/'
  };
  today: number = Date.now();
  links = [
    '',
    'home',
    'electrical',
    'nsbon-title',
    'switches-title',
    'surveillance-title',
    'nsbox-title',
    'products',
    'contacts',
    'events',
    'about-us',
    'news',
    'sfp-industrial-title',
    'poe-injector-title',
    'wtb'
  ];
  constructor() { }

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
  }

}
