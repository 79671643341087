import { Component, OnInit } from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-switches-list',
  templateUrl: './switches-list.component.html',
  styleUrls: ['./switches-list.component.less']
})
export class SwitchesListComponent implements OnInit {
  public products = [];
  public elements = [];
  public newColor = {};
  public env = environment;
  public host;
  constructor() { }

  ngOnInit() {
    this.host = this.env.getHost();
    this.products = JSON.parse(JSON.stringify(json.products));
    this.elements = json.switches_table;
    let colorVal = true;
    for (const j of this.elements) {
      for (const key of j.ports) {
        for (const k of key.value) {
          this.newColor[k] = colorVal;
          colorVal = !colorVal;
        }
      }
    }
  }

  getProduct(name) {
    const result = this.products[4].catalog.find(item => item.name === name && !(item.blocked && item.blocked.indexOf(this.host) > -1));
    return result;
  }
}
