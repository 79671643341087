import { Component, OnInit } from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-family-list',
  templateUrl: './family-list.component.html',
  styleUrls: ['./family-list.component.less']
})
export class FamilyListComponent implements OnInit {
  public products = [];
  public elements = [];
  public newColor = {};
  public env = environment;
  public host;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.host = this.env.getHost();
    this.route.queryParams.subscribe( queryParams => {
      this.env.lang = this.env.allLang.indexOf(queryParams.lg) > -1 ? queryParams.lg : 'en';
      this.env.queryParams = this.env.lang === 'en' ?  null : this.env.lang;
    });
    this.products = JSON.parse(JSON.stringify(json.products));
    this.elements = json.family_table;
    let colorVal = true;
    for (const j of this.elements) {
      for (const key of j.ports) {
        for (const k of key.value) {
          const item = this.getProduct(k);
          if (item) {
            this.newColor[k] = colorVal;
            colorVal = !colorVal;
          }
        }
      }
    }
  }

  getProduct(name, param?) {
    const result = this.products[1].catalog.find(item => {
      const blocked = item.blocked && item.blocked.indexOf(this.host) > -1;
      const eqName = param ? item[param] === name : item.name === name;
      return (eqName && !blocked);
    });
    return result;
  }
}
