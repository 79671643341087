import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-sfp-industrial-title',
  templateUrl: './sfp-industrial-title.component.html',
  styleUrls: ['./sfp-industrial-title.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SfpIndustrialTitleComponent implements OnInit {
  public products = [];
  public product: any = {};
  public loadedContent = true;
  public animation1 = false;
  public animation3 = false;
  public animation4 = false;
  public env = environment;
  public lg;
  public queryParams;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const clientHeight = document.documentElement.clientHeight;
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const elements = document.getElementsByClassName('animation-series');
    let top;
    if (elements.length) {
      for (let i = 0; i < elements.length; i++) {
        top = elements[i].getBoundingClientRect().top + scrollTop - clientTop;
        if (((docEl && docEl.scrollTop) || body.scrollTop) + clientHeight > top) {
          elements[i].classList.add('animation');
        } else {
          elements[i].classList.remove('animation');
        }
      }
    }
  }
  constructor(private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products[6];
    setTimeout( () => {this.loadedContent = true; this.cdr.detectChanges(); }, 500);
    setTimeout( () => {this.onWindowScroll(); }, 200);
    this.route.queryParams.subscribe( () => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      setTimeout( () => {this.onWindowScroll(); }, 200);
      this.cdr.detectChanges();
    });
  }
}
