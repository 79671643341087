import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {environment} from '../../../environments/environment';
import * as json from '../../../assets/mock/products.json';
@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.less']
})
export class BottomComponent implements OnInit {
  public env = environment;
  public products = [];
  @Output() onChanged = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
  }
  change() {
    this.onChanged.emit('1');
  }
}
