import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as json from '../../../assets/mock/products.json';
import {Products} from '../../domain/main';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'poe-injector',
  templateUrl: './poe-injector.component.html',
  styleUrls: ['./poe-injector.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PoeInjectorComponent implements OnInit {
  public products = [];
  public product: Products;
  public loadedContent = false;
  public lg;
  public queryParams;
  public env = environment;
  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products[5];
    setTimeout( () => this.loadedContent = true , 500);
    this.route.queryParams.subscribe( () => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
}
