import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import * as json from '../../../assets/mock/news.json';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) { }
  public stick = false;
  public news;
  public env = environment;
  public lg;
  public queryParams;
  public subs = [];
  public scrollId = null;
  public lastLang = null;
  public firstScroll = true;
  public filteredNews = [];
  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollPos = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) > 341 ? true : false;
    if (scrollPos !== this.stick) {
      this.stick = scrollPos;
    }
  }

  ngOnInit() {
    this.news = json.news;
  }
  getTitile(item: any) {
    if (this.env.getBlocked() && item.title_ae) {
      return item.title_ae;
    } else {
      return item.title[this.lg];
    }
  }
  ngAfterViewInit() {
    this.subs.push(this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      const host = this.env.getHost();
      this.filteredNews = this.news.filter(obj => (obj.lang.indexOf(this.lg) > -1 && !(obj.blocked && obj.blocked.indexOf(host) > -1)));
      this.scrollId = queryParams.id;
      // если меняеся язык, то делаем скролл с задержкой, чтобы успели перевестись статьи
      if (this.firstScroll || this.lastLang !== this.queryParams) {
        setTimeout( () => this.scrollFromUrl(), 500);
        this.firstScroll = false;
      } else {
        this.scrollFromUrl();
      }
      this.lastLang = this.queryParams;
      this.cdr.detectChanges();
    }));
  }

  scrollToNews(id) {
      // если еще раз вызываем статью с темже айди, то не вызывается метод подписка на смену урла, поэтому вызываем вручную скролл
      if (id === this.scrollId) {
        this.scrollFromUrl();
      }
      const el = document.getElementById(id);
      this.scrollId = el ? id.toString() : null;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          lg: this.queryParams,
          id: this.scrollId
        },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    }

    scrollFromUrl() {
      const el = document.getElementById(this.scrollId);
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'start'});
      } else {
        this.scrollToNews(null);
        window.scroll(0, 0);
      }
    }

    ngOnDestroy() {
      this.subs.filter(obj => {
      obj.unsubscribe();
    });
  }
}
