import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {SnackComponent} from '../../service/snack/snack.component';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {
  public name = '';
  public email = '';
  public text = '';
  public env = environment;
  // public emailName = this.env.getHost() === 'ae' ? 'order@networklogic.ae' : 'order@nsgate.com';
  public emailName = 'order@nsgate.com';
  constructor(private http: HttpClient,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.env.lang = this.env.allLang.indexOf(queryParams.lg) > -1 ? queryParams.lg : 'en';
      this.env.queryParams = this.env.lang === 'en' ?  null : this.env.lang;
    });
  }

  public sendForm() {
    const body = {
      to: this.emailName,
      message: this.name + '\n\n' + this.text,
      subject: this.email
    };
    return this.http.get('backend.php', {params: body }).subscribe(
      (data) => {
        this.snackBar.openFromComponent(SnackComponent, {
          duration: 2000,
          data: 'Message sent successfully',
          panelClass: ['green-snackbar'],
          verticalPosition: 'top'
        });
      },
      error => {
        if (error && error.statusText === 'OK') {
          this.snackBar.openFromComponent(SnackComponent, {
            duration: 2000,
            data: 'Message sent successfully',
            panelClass: ['green-snackbar'],
            verticalPosition: 'top'
          });
        } else {
          this.snackBar.openFromComponent(SnackComponent, {
            duration: 2000,
            data: 'Error, please try again',
            panelClass: ['red-snackbar'],
            verticalPosition: 'top'
          });
        }
      }
    );
  }
}
