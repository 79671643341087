import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-insert-html',
  templateUrl: './insert-html.component.html',
  styleUrls: ['./insert-html.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertHtmlComponent implements OnInit {
  public translateText = '';
  public lg;
  public queryParams;
  public env = environment;
  @Input() url: string;
  @Input() params: string[];
  @Input() output: string;
  constructor(private http: HttpClient,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.getText(this.lg);
    });
    this.route.params.subscribe(params => {
      this.getText(this.lg);
    });
  }
   public getText(lang) {
    const _this = this;
    this.http.get('assets/translate/' + this.url + '/' + lang + '.html', {responseType: 'text'}).subscribe(
      data => {
        let newstr = data;
        if (this.params && this.params.length) {
          this.params.forEach((item, numb) => {
            const find = '@' + numb + '@';
            const re = new RegExp(find, 'g');
            newstr = newstr.replace(re, item);
          });
        }
        // входящий параметр, какую часть текста взять #имя# из параметра [output]="'1'"
        if (this.output) {
          const arr = newstr.split('#' + this.output + '#');
          newstr = arr.length > 1 ? arr[1] : '';
        }
        _this.translateText = newstr;
        if (!_this.cdr['destroyed']) {
          _this.cdr.detectChanges();
        }
      },
      error => {
        if (lang !== 'en') {
          _this.getText('en');
        } else {
          _this.translateText = '<div class="content-error">Sorry, something went wrong</div>';
          if (!_this.cdr['destroyed']) {
            _this.cdr.detectChanges();
          }
        }
      }
    );
  }
}
