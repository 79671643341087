import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
@Component({
  selector: 'app-nsbon',
  templateUrl: './nsbon.component.html',
  styleUrls: ['./nsbon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NsbonComponent implements OnInit {
  public products = [];
  public product = null;
  public loadedContent = false;
  public env = environment;
  public animation = [];
  public lg;
  public queryParams;
  public top = [];
  public getJSON(url): Observable<any> {
    return this.http.get(url);
  }
  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private http: HttpClient) {
    this.getJSON('assets/mock/products.json').subscribe(data => {
      this.product = data.products[3];
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    setTimeout( () => this.loadedContent = true , 500);
    this.route.queryParams.subscribe( () => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
}
