import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import * as json from '../../../assets/mock/exhibitions.json';
import {Exhibitions} from '../../domain/main';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExhibitionsComponent implements OnInit, AfterViewInit {
  years: Exhibitions[];
  public imagesShowList = {};
  public currentImages = {};
  public leftImages = {};
  public allImages = {};
  public closeLight = true;
  public bigImage = {
    name: '',
    number: 0
  };
  public env = environment;
  public lg;
  public queryParams;
  public galleryWidth = 0;
  @ViewChild('galleryBlock', {static: false}) galleryBlock: ElementRef;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) { }
  ngAfterViewInit() {
    this.galleryWidth = this.galleryBlock.nativeElement.clientWidth;
    //console.log(this.galleryWidth);
  }
  ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
    const host = this.env.getHost();
    this.years = json.exhibitions;
    this.years.forEach(year => {
      let filteredList = [];
      year.list.forEach((list, numb) => {
        if (!(list.blocked && list.blocked.indexOf(host) > -1)) {
          const place = this.env.getLanguage(list.place, 'en');
          this.imagesShowList[list.name + year.year + place] = [];
          this.allImages[list.name + year.year + place] = [];
          if (list.dir) {
            list.photos.forEach((item, index) => {
              if (index < 4) {
                this.imagesShowList[list.name + year.year + place].push({
                  image: '../../assets/i/exhibitions/' + list.dir + '/' + item,
                  thumbImage: '../../assets/i/exhibitions/' + list.dir + '/small/' + item
                });
                this.currentImages[list.name + year.year + place] = index + 1;
              }
              this.allImages[list.name + year.year + place].push({
                image: '../../assets/i/exhibitions/' + list.dir + '/' + item,
                thumbImage: '../../assets/i/exhibitions/' + list.dir + '/small/' + item
              });
              this.leftImages[list.name + year.year + place] = 0;
            });
          }
          filteredList.push(list);
        }
      });
      year.list = filteredList;
    });
    console.log('1');
    this.cdr.detectChanges();
  }
  nextImage(name) {
    if (this.currentImages[name] < this.allImages[name].length) {
      this.currentImages[name]++;
      // если в массиве отображенных картинок, нет следующей, то добавляем ее.
      if (this.imagesShowList[name].length < this.currentImages[name]) {
        this.imagesShowList[name].push(this.allImages[name][this.currentImages[name] - 1]);
      }
      this.leftImages[name] = this.leftImages[name] - 375 - 7;
    } else if (this.currentImages[name] === this.allImages[name].length) {
        this.currentImages[name]++;
        this.leftImages[name] = this.leftImages[name] - (375 - this.galleryWidth % (375 + 7));
      }
  }
  prevImage(name) {
    if (this.currentImages[name] === this.allImages[name].length + 1) {
      this.currentImages[name]--;
      this.leftImages[name] = this.leftImages[name] + (375 - this.galleryWidth % (375 + 7));
    } else
    if (this.currentImages[name] - 4 > 0) {
      this.currentImages[name]--;
      this.leftImages[name] = this.leftImages[name] + 375 + 7;
    }
  }
  showLight(name, index) {
    this.closeLight = false;
    this.bigImage.name = name;
    this.bigImage.number = index;
  }
  onResize(event) {
    this.galleryWidth = this.galleryBlock.nativeElement.clientWidth;
    //console.log(this.galleryWidth);
  }
}
