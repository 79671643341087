// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lang: 'en',
  allLang: ['en', 'ru'],
  menuArray: [0, 1, 2, 3, 4, 5, 6, 7],
  queryParams: '',
  hostname: window.location.hostname,
  getHost() {
    //return 'eu';
    return this.hostname.slice(this.hostname.lastIndexOf('.') + 1);
  },
  getBlocked() {
    return this.getHost() === 'localhost' || this.getHost() === 'ae' || this.getHost() === 'com';
  },
  getLanguage(item, lg?) {
    if (!item) {
      return false;
    }
    if (!lg) {
      if (item[this.lang]) {
        return item[this.lang];
      } else if (item.en === null) {
        return false;
      } else if (item.en) {
        return item.en;
      } else {
        return item;
      }
    } else {
      if (item[lg]) {
        return item[lg];
      } else {
        return item;
      }
    }
  },
  getBlockedItem(item) {
    return !(item.blocked && item.blocked.indexOf(this.getHost()) > -1);
  }
};
