
import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.less']
})
export class AwardsComponent implements OnInit {
  public env = environment;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
     this.route.queryParams.subscribe( queryParams => {
      this.env.lang = this.env.allLang.indexOf(queryParams.lg) > -1 ? queryParams.lg : 'en';
      this.env.queryParams = this.env.lang === 'en' ?  null : this.env.lang;
    });
  }

}
