import {ChangeDetectorRef, Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import * as json from '../../../assets/mock/news.json';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import KeenSlider, {KeenSliderInstance} from 'keen-slider';
@Component({
  selector: 'app-home3',
  templateUrl: './home3.component.html',
  styleUrls: ['./home3.component.less', '../../../../node_modules/keen-slider/keen-slider.min.css']
})
export class Home3Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderRef', {static: false}) sliderRef: ElementRef<HTMLElement>;
  public loadedContent = false;
  public lg;
  public queryParams;
  public env = environment;
  public news;
  public filteredNews = [];
  public slider: KeenSliderInstance = null;
  public currentSlide = 0;
  public galleryWidth;
  public dotHelper: Array<number> = [];
  public imgList = [
    {img: 'gal1.png', width: 915},
    {img: 'gal2.png', width: 798},
    {img: 'gal3.png', width: 853},
    {img: 'gal4.png', width: 775},
    {img: 'gal5.png', width: 629},
    {img: 'gal6.png', width: 860}
  ];
  public bigGallery = [
    {
      name: 'Exterior access nodes',
      subText: '| Outdoor Switches |',
      img: 'nsbox-124sl-30_tb.png',
      text: '2-16 PoE ports for video cameras<br>fibre-optic or wireless connect',
      link: 'nsbox-title'
    },
    {
      name: 'Equipped electrical Enclosures',
      subText: '| Steel | GRP | Polycarbonate |',
      img: '3838f1.png',
      text: 'Mechanical | Electrical<br>Climatic control system',
      link: 'electrical'
    },
    {
      name: 'Backup power solutions',
      subText: '',
      img: 'cloudy_1w.png',
      text: 'weatherproof Outdoor UPS<br>Solar panel | AC power source',
      link: 'backupsolutions-title'
    },
    {
      name: 'Enclosure Cooling solutions',
      subText: '',
      img: 'NSB-4040C4.png',
      text: 'Thermoelectric coolers<br>Vandal-proof Filter Fans kit',
      link: 'surveillance',
      id: 'NSBox_ICE'
    },
    {
      name: 'Accessories for electrical Enclosures',
      subText: '',
      img: 'nsbon-19f3_tb.png',
      text: 'Mechanical | Electrical<br>Climatic control system',
      link: 'nsbon-title'
    },
    {
      name: 'Industrial PoE Switches',
      subText: '',
      img: 'nis-3200x3m.png',
      text: '2/4/8/16 PoE ports<br>IEEE 802.3af/.3at/.3bt',
      link: 'switches-title'
    },
    {
      name: 'Industrial PoE Extenders',
      subText: '',
      img: 'nrp-192p.png',
      text: 'Rugged weatherproof housing',
      link: 'poe-injector-title'
    },
    {
      name: 'Industrial SFP Modules',
      subText: '',
      img: 'sw.png',
      text: 'Single fiber | Dual fiber',
      link: 'sfp-industrial-title'
    }
  ];
  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    const host = this.env.getHost();
    this.news = json.news;
    setTimeout(() => this.loadedContent = true, 500);
    this.route.queryParams.subscribe(() => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.filteredNews = this.news.filter(obj => (obj.lang.indexOf(this.lg) > -1 && !(obj.blocked && obj.blocked.indexOf(host) > -1)));
      this.cdr.detectChanges();
    });
    this.galleryWidth = window.innerWidth < 600 ? window.innerWidth : 0;
  }
  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        loop: true,
        mode: 'snap',
        rtl: false,
        slides: { perView: "auto", spacing: 0},
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
          this.cdr.detectChanges();
        },
      },
      [
        (slider) => {
          let timeout;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              slider.next();
            }, 5000);
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on('dragStarted', clearNextTimeout);
          slider.on('animationEnded', nextTimeout);
          slider.on('updated', nextTimeout);
        },
      ]
    );
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ];

    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
