import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-sfp-industrial-list',
  templateUrl: './sfp-industrial-list.component.html',
  styleUrls: ['./sfp-industrial-list.component.less']
})
export class SfpIndustrialListComponent implements OnInit {
  public table = [];
  public products = [];
  public product = {};
  public newColor = {};
  public lg;
  public queryParams;
  public env = environment;
  public host;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products[6];
    this.host = this.env.getHost();
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    this.table = json.sfp_industrial_table;
    let colorVal = true;
    for (const j of this.table) {
      for (const key of j.ports) {
        for (const k of key.value) {
          const item = this.getElement(k);
          if (item) {
            this.newColor[k] = colorVal;
            colorVal = !colorVal;
          }
        }
      }
    }
  }

  getElement(id) {
    const result = this.products[6].catalog.find(item => (item.id === id ));
    return result;
  }
}
