import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as json from '../../../../assets/mock/products.json';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-backup-power-solutions-list',
  templateUrl: './backup_power_solutions-list.component.html',
  styleUrls: ['./backup_power_solutions-list.component.less']
})
export class BackupPowerSolutionsListComponent implements OnInit {
  @Input() imgGallery: any;
  public electricalTable = [];
  public products = [];
  public product = {};
  public newColor = {};
  public lg;
  public queryParams;
  public env = environment;
  public host;
  public imgList = {};
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products[7];
    this.host = this.env.getHost();
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    this.electricalTable = json.backup_power_solutions;
    for (const j of this.electricalTable) {
      let colorVal = true;
      for (const key of j.ports) {
        this.imgList[j.id] = this.getImgList(key);
        for (const k of key.value) {
          const item = this.getNsbox(k, key.type);
          if (item) {
            this.newColor[k + key.type] = colorVal;
            colorVal = !colorVal;
          }
        }
      }
    }
    console.log(this.imgList);
  }

  getNsbox(name, type) {
    const result = this.products[7].catalog.find(item => (item.name === name && !(item.blocked && item.blocked.indexOf(this.host) > -1)));
    if (result && result.sets) {
      result.sets = result.sets.filter(item => !(item.blocked && item.blocked.indexOf(this.host) > -1));
    }
    return result;
  }
  getImgList(ports) {
    const imgArr = [];
    for (const j of ports.value) {
      const currentNsbox = this.getNsbox(j, ports.type);
      if (currentNsbox) {
        for (const k of currentNsbox.sets) {
          if (k.img) {
            imgArr.push(k);
          }
        }
      }
    }
    return imgArr;
  }
}
