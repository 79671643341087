import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.less']
})
export class ClientsComponent implements OnInit {
  public loadedContent = false;
  public imagesList = [
    ['Udmurtneft.png', 'https://www.rosneft.com/'],
    ['71878077_StartLogo_inglish.png', 'https://www.startatom.ru/'],
    ['Sochi_2014_-_Logo.svg.png', 'https://www.olympic.org/sochi-2014'],
    ['rzd.png', 'https://eng.rzd.ru/'],
    ['TOPNETlogo.png', 'http://www.topnetdistribution.com/'],
    ['def.png', 'http://eng.mil.ru/en/index.htm'],
    ['ros.png', 'https://www.company.rt.ru/en/'],
    ['rosavtodor_eng.png', 'https://rosavtodor.gov.ru/'],
    ['partners_moek.png', 'https://www.moek.ru/'],
    ['Norilsk_Nickel.png', 'https://www.nornickel.com/'],
    ['Moscow_subway.png', 'https://mosmetro.ru/'],
    ['Nikirat.png', 'https://www.nikiret.ru/s2/'],
    ['LUK_OIL_Logo_lat.svg.png', 'https://www.lukoil.com/'],
    ['Kazakhmys_logo.svg.png', 'http://www.kazakhmys.kz/en/'],
    ['kamaz.png', 'https://kamaz.ru/en/'],
    ['Irkutskenergo.png', 'https://www.irkutskenergo.ru/'],
    ['GAZPROMNEFT.jpg', 'https://www.gazprom-neft.com/'],
    ['bashneft.png', 'http://www.bashneft.ru/'],
    ['Atomstroiexport_logo.svg.png', 'https://ase-ec.ru/en/'],
    ['ASS_n.jpg', 'https://www.assgmbh.eu/'],
    ['ALROSA_Rus_COLOUR_RGB.jpg', 'http://www.alrosa.ru/'],
    ['800px-FIFA_World_Cup_2018_Logo.svg.png', 'https://www.fifa.com/']
  ];
  constructor() { }

  ngOnInit() {
    setTimeout( () => this.loadedContent = true , 500);
  }

}
