import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {KeenSliderInstance} from 'keen-slider';
import KeenSlider from 'keen-slider';
@Component({
  selector: 'app-slider-gallery',
  templateUrl: './slider-gallery.component.html',
  styleUrls: ['./slider-gallery.component.less', '../../../../node_modules/keen-slider/keen-slider.min.css']
})
export class SliderGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  //@Input() imgList: any;
  public _imgList: any;
  public dotHelper: Array<number> = [];
  @Input() set imgList(imgList: any) {
    this._imgList = imgList;
    if (this.sliderRef) {
      this.loaded = false;
      this.currentSlide = 0;
      this.slider.update(undefined, 0);
      setTimeout( () => { this.loaded = true; this.cdr.detectChanges(); this.updategallery(); }, 10);
    }
  }
  @Input() imgTypeId: any;
  @Input() little: any;
  @Input() widthGallery: any;
  @Input() diffImgWidth: any;
  @Input() innerHeight: any;
  @ViewChild('sliderRef', {static: false}) sliderRef: ElementRef<HTMLElement>;
  @ViewChild('dots', {static: false}) dots: ElementRef<HTMLElement>;
  public slider: KeenSliderInstance = null;
  public currentSlide = 0;
  public loaded = true;
  public galleryWidth;
  constructor(private cdr: ChangeDetectorRef) {

  }
  ngOnInit() {
    if (this.diffImgWidth) {
      this.galleryWidth = window.innerWidth < 600 ? window.innerWidth : 0;
    } else {
      if (this.little) {
        this.galleryWidth = window.innerWidth < 500 ? (window.innerWidth - 60) : 260;
      } else {
        this.galleryWidth = window.innerWidth < 500 ? (window.innerWidth - 60) : 400;
      }
      if (this.widthGallery) {
        this.galleryWidth = window.innerWidth < 500 ? (window.innerWidth - 60) : this.widthGallery;
      }
    }
  }
  ngAfterViewInit() {
    this.updategallery();
  }
  updategallery() {
    const spacing = this.little ? 50 : 20;
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        loop: true,
        mode: 'snap',
        rtl: false,
        slides: { perView: "auto", spacing: spacing},
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
          this.cdr.detectChanges();
        },
      },
      [
        (slider) => {
          let timeout;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              //slider.next();
            }, 4000);
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on('dragStarted', clearNextTimeout);
          slider.on('animationEnded', nextTimeout);
          slider.on('updated', nextTimeout);
        },
      ]
    );
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ];
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
